<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" title="Mailing list - aide" size="lg">
        <div class="text-center">
            <p class="text-underline">Apparence du mail qui sera envoyé :</p>
            <p>
                <b-img :src="image" alt="Help image" class="mw-100"/>
            </p>
            <p class="text-underline">Pour ce qui est des destinataires :</p>
            <table class="text-left table table-bordered">
                <tbody>
                <tr>
                    <td><strong>Utilisateurs valides</strong></td>
                    <td>
                        Tous les utilisateurs inscrits sur le site (y compris les "invités" qui n'ont pas
                        d'association)
                    </td>
                </tr>
                <tr>
                    <td><strong>Clujistes</strong></td>
                    <td>Membres du cluji pour cette année</td>
                </tr>
                <tr>
                    <td><strong>Associations extérieures</strong></td>
                    <td>Membres d'une association non-Cluji</td>
                </tr>
                <tr>
                    <td><strong>Cluji + autres assos</strong></td>
                    <td>Membres dont le compte a été validé par le bureau (donc membres d'une asso quelle qu'elle soit).
                        C'est donc la même chose que "Utilisateurs valides" mais sans les invités
                    </td>
                </tr>
                </tbody>
            </table>
            <p>Les adresses mails des destinataires sont anonymisées (i.e. mises en "Blank Carbon Copy") pour que chaque
                personne qui reçoit le mail ne voie pas les autres adresses.</p>

            <b-button @click="hideModal">Fermer</b-button>
        </div>
    </b-modal>
</template>

<script>
    import modalMixin   from '@/mixin/modalMixin';
    import {requireImg} from '@/util/resources';

    export default {
        name: "MailerHelpModal",
        mixins: [modalMixin],
        data: () => ({
            modalRef: 'mailerHelpModal'
        }),
        computed: {
            image() {
                return requireImg('mail_help.jpg');
            }
        }
    }
</script>

<style scoped>
    table td {
        white-space: normal;
    }

    table td:first-child {
        width: 25%;
    }
</style>